.social{
    position: absolute;
    bottom: -2.5%;
    left: 50%;
    transform: translateX(-50%);

}
.social *{
    border: 1px solid rgb(113, 113, 113);
    background: #fff;
    margin: 0px 0.5rem;
    padding: 0.25rem;
    border-radius: 50%;
    font-size: 2rem !important;
}