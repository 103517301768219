.separator{
    position: relative;
    background: #fff;
    height: 0.3rem;
    width: 2rem;
    border-radius: 1rem;
  }
  .separator::before{
    content: " ";
    background: #fff;
    position: absolute;
    left: -40%;
    width: 0.5rem;
    height: 0.3rem;
    border-radius: 50%;
  }
  .separator::after{
    content: " ";
    background: #fff;
    position: absolute;
    right: -40%;
    width: 0.5rem;
    height: 0.3rem;
    border-radius: 50%;
  }