.pricings{
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translateX(-50%)
  }
  .price-btn{
    position: absolute;
    bottom: -3.5%;
    background-color: white !important;
    left: 50%;
    transform: translateX(-50%);
  }
  .price-btn:hover{
    background-color: #27ae5f !important;

  }