@import url('./pricing.css');
@import url('./team.css');
@import url('./footer.css');
@import url('./separator.css');
.d{
  border: 1px solid red;
}
.hero{
  display: flex;
  justify-content: center;
  padding-top: 7.5rem;
}
.header-img img{
  /* border-bottom: 2px solid #27ae5f; */
  box-shadow: 0px 20px 6px #27ae5f;
}
.call{
  height: 75vh;
  width: 100%;
  background: linear-gradient(rgba(0, 255, 0, 0.7),rgba(0, 255, 0, 0.5)), no-repeat;
  background-size: cover;
  background-position: bottom;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-attachment: fixed;
}
.content{
  z-index: 10;
}
.back{
  z-index: -10;
}
.call::before{
  content: ' ';
  width: 100%;
  height: 100%;
  background: rgba(76, 171, 87, 0.851);
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
}
.text-green{
  color: #27ae5f !important;
}
.bg-green{
  background: #27ae5f !important;
  outline: #27ae5f !important;
  border: #27ae5f !important;
}
@media screen and (max-width: 769px) {
  .bg-sm-green{
  background: #27ae5f !important;
  }
}
.bg-cayn{
  background: #02dbc0 !important;
  color: #fff;
}
.text-green{
  color: #27ae5f !important;
}
.bg-success{
  background: red !important;
}
.navbar{
  z-index: 10 !important;
}
.light-border{
  border: 1px solid #fff !important;
}

.list{
  list-style: none;
}
